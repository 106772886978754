import { Utils } from "@adobe/aem-react-editable-components";
import { ChunkExtractorManager } from "@loadable/server";
import AEMPublishRouter from "aem/AEMPublishRouter";
import CountryPriceNotification from "component-country-price-notification";
import AboveNavPromo from "component-promo/AboveNavPromo";
import PromoDialogContainer from "component-promo/PromoDialogContainer";
import ReloadForATGController from "component-reload-for-atg-controller";
import ScrollManager from "component-scroll-manager";
import ContainerApp, { AppDefaultProps } from "container-app";
import Provider from "custom-providers/Provider";
import express from "express";
import { processEnvServer } from "hooks/useSsrHooks";
import React from "react";
import { BrowserRouter, StaticRouter, Link } from "react-router-dom";
import memoize from "utils/memoize";
import type { ClientType } from "graphql-client/client-type";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import "aem/import-components.publish";
import AEMPublishPage from "aem/AEMPublishPage";
import { getModelClient } from "aem/CustomModelClientSide";
import graphqlClient from "graphql-client";
const isEditor = Utils.isInEditor();
const env = useEnv();
const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);

const App = (props: {
  location?: string;
  extractor?: any;
  cookiePersistor?: any;
  req?: express.Request;
  isSSR?: boolean;
  footerModel?: any;
  routerContext?: any;
  client: ClientType;
  isLocalStorageUpdate?: boolean;
}) => {
  const modelClient = getModelClient({ client: graphqlClient });
  const aemPublishPage = AEMPublishPage;
  return processEnvServer ? (
    <ChunkExtractorManager extractor={props.extractor}>
      <StaticRouter location={props.location} context={props.routerContext}>
        <Provider
          cookiePersistor={props.cookiePersistor}
          req={props.req}
          client={props.client}
        >
          <ReloadForATGController>
            {!FEATURE_INTERNATIONAL && <CountryPriceNotification />}
            <ContainerApp>
              <Link to={"/membership"} />
              <AboveNavPromo />
              <AEMPublishRouter
                client={props.client}
                aemPublishPage={aemPublishPage}
                modelClient={modelClient}
              />
            </ContainerApp>
            <PromoDialogContainer />
          </ReloadForATGController>
        </Provider>
      </StaticRouter>
    </ChunkExtractorManager>
  ) : (
    <BrowserRouter>
      <Provider client={props.client}>
        <ReloadForATGController>
          {!FEATURE_INTERNATIONAL && <CountryPriceNotification />}
          {!isEditor && <ScrollManager />}
          <ContainerApp
            rootStyle={{
              minHeight: isEditor
                ? "100%"
                : (AppDefaultProps?.rootStyle as any)?.minHeight
            }}
          >
            <AboveNavPromo />
            <AEMPublishRouter
              client={props.client}
              aemPublishPage={aemPublishPage}
              modelClient={modelClient}
              isLocalStorageUpdate={props.isLocalStorageUpdate}
            />
          </ContainerApp>
          <PromoDialogContainer />
        </ReloadForATGController>
      </Provider>
    </BrowserRouter>
  );
};

export default memoize(App);

import {
  MapTo,
  Page,
  PageProperties,
  withModel
} from "@adobe/aem-react-editable-components";
import { PageModel } from "@RHCommerceDev/aem/types/pageModel";
import React, { memo } from "react";
import isEqual from "react-fast-compare";
import Helmet from "react-helmet";
import StaggerLoad from "aem/StaggerLoad";
import { processEnvServer } from "hooks/useSsrHooks";
import { getReqContext } from "utils/reqContext";
import yn from "yn";
import { useEnv } from "hooks/useEnv";

export interface AEMPublishPageProps extends PageProperties {
  pageModel: PageModel;
  isAsync?: boolean;
  homeSchema?: string;
}

export class AEMUnmappedPublishPage extends Page<AEMPublishPageProps, any> {
  //TODO: remove once validation of async impl is complete.
  // shouldComponentUpdate() {
  //   // TODO: what was the case here?
  //   return false;
  // }

  render() {
    const req = getReqContext();
    const path = processEnvServer ? (req as any).path : location.pathname;
    //todo: replace this with a property in AEM to control this
    const overridePages = ["our-company", "customer-experience"];
    const isOverridePage = overridePages.some(page => {
      return path.indexOf(page) > -1;
    });

    const env = useEnv();

    const canonical = document.querySelector(
      'link[rel="canonical"]'
    ) as HTMLLinkElement;
    const baseURL = `https://${window.location.hostname}`;

    return (
      <>
        {!this.props.isAsync &&
          ((this.props.pageModel as any)?.title ||
            this.props.pageModel?.pageTitle) && (
            <Helmet>
              <title>
                {this.props.pageModel?.pageTitle ||
                  (this.props.pageModel as any)?.title}
              </title>
              <meta
                name="pageTitle"
                content={
                  this.props.pageModel?.pageTitle ||
                  (this.props.pageModel as any)?.title
                }
              />
              <meta
                property="og:title"
                content={
                  this.props.pageModel?.pageTitle ||
                  (this.props.pageModel as any)?.title
                }
              />
              <meta
                property="og:image"
                content="https://media.restorationhardware.com/is/image/rhis/1200x630_RH_Open_Graph_Image?wid=1000&fmt=jpeg"
              />
              {this.props.homeSchema && (
                <script type="application/ld+json" id="home-schema">
                  {this.props.homeSchema}
                </script>
              )}
            </Helmet>
          )}
        {!this.props.isAsync &&
          (this.props.pageModel?.pageDesc ||
            (this.props.pageModel as any)?.description) && (
            <Helmet>
              <meta
                name="description"
                content={
                  this.props.pageModel?.pageDesc ||
                  (this.props.pageModel as any)?.description
                }
              />
              <meta
                property="og:description"
                content={
                  this.props.pageModel?.pageDesc ||
                  (this.props.pageModel as any)?.description
                }
              />
              <meta
                name="pageDescription"
                content={
                  this.props.pageModel?.pageDesc ||
                  (this.props.pageModel as any)?.description
                }
              />
            </Helmet>
          )}
        {!this.props.isAsync &&
          (this.props.pageModel[":path"] ||
            (this.props.pageModel as any)?.path) &&
          canonical &&
          yn(env.FEATURE_CANONICAL_URLS) && (
            <Helmet>
              <link rel="canonical" href={`${baseURL}` + `${path}`} />
            </Helmet>
          )}

        <div
          className={this.props?.pageModel?.cssClassNames}
          style={{ backgroundColor: this.props?.pageModel?.rhProps?.bgColor }}
        >
          {!this.props.isAsync && !isOverridePage && (
            <StaggerLoad childComponents={this.childComponents} />
          )}
          {(this.props.isAsync || isOverridePage) && this.childComponents}
        </div>
      </>
    );
  }
}

export const AEMPublishPage = MapTo<AEMPublishPageProps>(
  "rh/components/structure/page"
)(withModel(AEMUnmappedPublishPage));

export const AEMMemoizedPublishPage = memo(AEMPublishPage, isEqual);

export default AEMMemoizedPublishPage;

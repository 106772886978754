import { constPropsKey, pathKey } from "./createAuthoringProps";

export function createProxyForPathExtraction(propPath: string = "."): any {
  return new Proxy(
    {
      [pathKey]: propPath,

      toString: () => propPath
    },
    {
      get(target: any, prop: string) {
        if (typeof prop === "symbol") {
          return target[prop];
        }

        if (prop === "toString") {
          return target.toString;
        }

        if (target[constPropsKey] && prop in target[constPropsKey]) {
          return target[constPropsKey][prop];
        }

        try {
          const path = `${target[pathKey]}/${prop}`;

          return createProxyForPathExtraction(path);
        } catch (ex) {
          console.log(target, target[pathKey], prop, ex);

          throw ex;
        }
      }
    }
  );
}

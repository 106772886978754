import { FormTextFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/textfield/FormTextField";
import { SlingElement, SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type ResponsiveFieldProps = Omit<SlingElementProps, "tagName"> &
  FormTextFieldProps & {
    rhFieldMaxWidth?: string;
    rhFieldWidth?: string;
  };

export type ResponsiveFieldFC = React.FC<ResponsiveFieldProps>;

export const ResponsiveField: ResponsiveFieldFC = ({
  children,
  name,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="rh/fields/responsivefield"
      {...rest}
      tagName={rest["tagName"] || toTagName(name)}
      name={name}
    >
      {children}
    </SlingElement>
  );
};

ResponsiveField.defaultProps = {
  rhFieldMaxWidth: "100px",
  rhFieldWidth: "20%"
};

export default ResponsiveField;

import React from "react";
import Layout, { LayoutProps } from "./Layout";

export type GridLayoutProps = LayoutProps & {
  /**
   * The default align-self property for each field.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/align-self
   */
  defaultAlignSelf?: string;

  /**
   * The same as https://developer.mozilla.org/en-US/docs/Web/CSS/gap.
   */
  gap?: string;

  /**
   * The same as https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows.
   */
  gridTemplateRows?: string;

  /**
   * The same as https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns.
   */
  gridTemplateColumns?: string;

  /**
   * The same as https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-areas.
   */
  gridTemplateAreas?: string;
};

export type GridLayoutFC = React.FC<Omit<GridLayoutProps, "type">>;

export const GridLayout: GridLayoutFC = ({
  children,
  defaultAlignSelf = "flex-end",
  ...rest
}) => {
  return (
    <Layout
      {...rest}
      defaultAlignSelf={defaultAlignSelf}
      type="rh/layouts/grid"
    >
      {children}
    </Layout>
  );
};

GridLayout.defaultProps = {
  ...Layout.defaultProps
};

export default GridLayout;

import { SlingElement, SlingElementProps } from "aem/ui/sling";
import React from "react";

export type FixedColumnsProps = Omit<SlingElementProps, "tagName">;

export type FixedColumnsFC = React.FC<FixedColumnsProps>;

export const FixedColumns: FixedColumnsFC = ({ children, tagName }) => {
  return (
    <SlingElement
      tagName={tagName}
      sling_resourceType="granite/ui/components/coral/foundation/fixedcolumns"
    >
      {children}
    </SlingElement>
  );
};

FixedColumns.defaultProps = {
  tagName: "fixedColumns"
};

export default FixedColumns;

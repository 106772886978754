import { SlingElement, SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type RevealProps = Omit<SlingElementProps, "tagName"> & {
  title?: string;
};

export type RevealFC = React.FC<RevealProps>;

export const Reveal: RevealFC = ({ children, title, ...rest }) => {
  return (
    <SlingElement
      sling_resourceType="rh/fields/reveal"
      {...rest}
      tagName={rest["tagName"] || toTagName(title)}
      title={title}
    >
      {children}
    </SlingElement>
  );
};

Reveal.defaultProps = {};

export default Reveal;

import loadable from "@loadable/component";
import Body from "container-body";
import HeaderSwitcher from "container-header-switcher";
//import GlobalPopup from "containers/GlobalPopup";
import useBrand, {
  brandContextMap,
  SUBDOMAIN,
  getHostName
} from "hooks-use-brand/useBrand";
import { useEnv } from "hooks/useEnv";
import { processEnvServer } from "hooks/useSsrHooks";
import React, {
  FC,
  ReactNode,
  useState,
  useCallback,
  useMemo,
  useEffect
} from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  RouteComponentProps
} from "react-router-dom";
import { Brand } from "types";
import buildRoomsUrl from "utils/buildRoomsUrl";
import memoize from "utils/memoize";
import { PG_SEARCH_TERM } from "utils/constants";

import passthroughToOldExperience from "utils/passthroughToOldExperience";
import yn from "yn";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useParams2 } from "hooks/useParams";
import usePrevious from "hooks/usePrevious";
import CollectionGallery from "page-collection-gallery";
import { getCountryLanguageFromURL } from "utils/getCountryFromUrl";
import {
  hreflangOptionsUnique,
  noneEnglishLocales
} from "@RHCommerceDev/resources/countries-config.json";
import CountrySiteCheckDialog from "@RHCommerceDev/dialog-country-checker";

import RHRProductGallery from "page-rhr-product-gallery";
import PDPSwitcher from "component-rhr-pdp-switcher";
import RHRFooter from "containers/RHRFooter";
import PageNotFoundError from "component-page-not-found-error";
import NextgenProductGallery from "nextgen-product-gallery";
import NextgenCollectionGallery from "nextgen-collection-gallery";
import SanitizedRoute from "utils/SanitizedRoute";

import { splunkRumInit, splunkSessionRecorderInit } from "utils/splunk";
import { getLocaleFromPath } from "utils/intlUtils";
import RHCookiePermissionBannerWrapper from "component-rh-cookie-permission-banner/RHCookiePermissionBannerWrapper";
import useCheckEmailPopUp from "hooks/useCheckEmailPopUp/useCheckEmailPopUp";
import { useFetchParams } from "hooks/useFetchParams/useFetchParams";

const env = useEnv();

// Initialize _Splunk RUM_
if (!processEnvServer && yn(env.FEATURE_SPLUNK_RUM)) {
  splunkRumInit();
}

// Initialize _Splunk Session-Recorder_
if (!processEnvServer && yn(env.FEATURE_SPLUNK_SESSION_RECORDER)) {
  splunkSessionRecorderInit();
}

// pages working CLIENT and SERVER side
const Home = loadable(
  () => import(/* webpackChunkName: "page-home" */ "page-home"),
  { fallback: <></> }
);

const Product = loadable(
  () => import(/* webpackChunkName: "page-product" */ "page-product"),
  { fallback: <></> }
);

const EmailSignupContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "app/EmailSignupContainer" */ "app/EmailSignupContainer"
    )
);
const GuestHouse = loadable(
  () => import(/* webpackChunkName: "page-guest-house" */ "page-guest-house")
);
// !processEnvServer, means these routes will run CLIENT side
const Rooms = !processEnvServer
  ? loadable(() => import(/* webpackChunkName: "page-rooms" */ "page-rooms"), {
      fallback: <></>
    })
  : null;
const MyAccountPageSignIn = yn(env.FEATURE_REACT_MY_ACCOUNT_SIGNIN)
  ? loadable(
      () => import(/* webpackChunkName: "page-my-account" */ "page-my-account"),
      { fallback: <></> }
    )
  : null;

const MyAccountAddress = yn(env.FEATURE_REACT_MY_ACCOUNT_ADDRESS)
  ? loadable(
      () => import(/* webpackChunkName: "page-my-account" */ "page-my-account"),
      { fallback: <></> }
    )
  : null;
const SalePage = !processEnvServer
  ? loadable(() => import(/* webpackChunkName: "page-sale" */ "page-sale"), {
      fallback: <></>
    })
  : null;

const MyAccountWishlist = !processEnvServer
  ? loadable(
      () => import(/* webpackChunkName: "page-my-account" */ "page-my-account"),
      { fallback: <></> }
    )
  : null;
const CategoryContextPage = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-category-context" */ "page-category-context"
        ),
      { fallback: <></> }
    )
  : null;
const PromoPage = !processEnvServer
  ? loadable(() => import(/* webpackChunkName: "page-promo" */ "page-promo"), {
      fallback: <></>
    })
  : null;
const AllGalleries = loadable(
  () =>
    import(/* webpackChunkName: "page-all-galleries" */ "page-all-galleries"),
  { fallback: <></> }
);

//import AllGalleries from "page-all-galleries";
const CustomerServicePage = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-customer-service" */ "page-customer-service"
        ),
      { fallback: <></> }
    )
  : null;
const OrderDetails = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-order-details" */ "page-order-details"
        ),
      { fallback: <></> }
    )
  : null;
const Return = !processEnvServer
  ? loadable(
      () => import(/* webpackChunkName: "page-return" */ "page-return"),
      { fallback: <></> }
    )
  : null;
const ReturnReason = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-return-reason" */ "page-return-reason"
        ),
      { fallback: <></> }
    )
  : null;
const ReturnConfirmation = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-return-confirmation" */ "page-return-confirmation"
        ),
      { fallback: <></> }
    )
  : null;
const ShoppingCartPage =
  !processEnvServer && yn(env.FEATURE_REACT_CART)
    ? loadable(
        () =>
          import(
            /* webpackChunkName: "page-shopping-cart" */ "page-shopping-cart"
          ),
        { fallback: <></> }
      )
    : null;
const MyAccountPage =
  !processEnvServer && yn(env.FEATURE_REACT_MY_ACCOUNT)
    ? loadable(
        () =>
          import(/* webpackChunkName: "page-my-account" */ "page-my-account"),
        { fallback: <></> }
      )
    : null;
const CheckoutPageV2 = !processEnvServer
  ? loadable(
      () =>
        import(/* webpackChunkName: "page-checkout-v2" */ "page-checkout-v2"),
      { fallback: <></> }
    )
  : null;
const PaymentPortalRedirect = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-checkout-v2" */ "page-checkout-v2/pages/PaymentPortalRedirect/PaymentPortalRedirect"
        ),
      { fallback: <></> }
    )
  : null;
const SignIn =
  !processEnvServer &&
  (yn(env.FEATURE_REACT_CART) || yn(env.FEATURE_REACT_MY_ACCOUNT_SIGNIN))
    ? loadable(
        () => import(/* webpackChunkName: "page-sign-in" */ "page-sign-in"),
        { fallback: <></> }
      )
    : null;

//import GalleryDetails from "pages/GalleryDetails";
const SwatchFulfillment =
  !processEnvServer && yn(env.FEATURE_REACT_SWATCH)
    ? loadable(
        () =>
          import(
            /* webpackChunkName: "page-swatch-fulfillment" */ "page-swatch-fulfillment"
          ),
        {
          fallback: <></>
        }
      )
    : null;
const SelfScheduler =
  !processEnvServer && yn(env.FEATURE_SELF_SCHEDULER_APP)
    ? loadable(
        () =>
          import(
            /* webpackChunkName: "page-self-scheduler" */ "page-self-scheduler"
          ),
        { fallback: <></> }
      )
    : null;
const GiftRegistry = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-gift-registry" */ "page-gift-registry"
        ),
      { fallback: <></> }
    )
  : null;
//TODO: merge them under one env variable when order details is live
const GuestUserOrderDetails = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-order-details" */ "page-order-details"
        ),
      { fallback: <></> }
    )
  : null;
const ContractSalesPage =
  !processEnvServer && yn(env.FEATURE_REACT_CONTRACT_SALES)
    ? loadable(
        () =>
          import(
            /* webpackChunkName: "page-contract-sales" */ "page-contract-sales"
          ),
        { fallback: <></> }
      )
    : null;
const TradeSalesPage =
  !processEnvServer && yn(env.FEATURE_REACT_TRADE_SALES)
    ? loadable(
        () =>
          import(/* webpackChunkName: "page-trade-sales" */ "page-trade-sales"),
        { fallback: <></> }
      )
    : null;

/** PAYMENT PORTAL start */
const Payment = loadable(
  () => import(/* webpackChunkName: "page-payment" */ "page-payment")
);
const PaymentThankYou = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-payment-thank-you" */ "page-payment-thank-you"
        ),
      { fallback: <></> }
    )
  : null;
const ExpiredOrder = !processEnvServer
  ? loadable(
      () =>
        import(
          /* webpackChunkName: "page-payment-expiredorder" */ "page-payment-expiredorder"
        ),
      { fallback: <></> }
    )
  : null;
const BillingSummary = loadable(
  () =>
    import(
      /* webpackChunkName: "page-billing-summary" */ "page-billing-summary"
    )
);

const PrintableProductPage = loadable(
  () =>
    import(
      /* webpackChunkName: "page-billing-summary" */ "printable-pages/product"
    )
);

const SwitchPG: FC<RouteComponentProps> = props => {
  const { location } = props;
  const [error, setError] = useState(false);
  const prevLocationSearch = usePrevious(location.search);
  const { params } = useParams2<{ [key: string]: string }>(
    { [PG_SEARCH_TERM]: "", sale: "", version: "", categoryId: "" },
    { toLowerCase: true }
  );

  const _params = useFetchParams(
    location.pathname,
    yn(env.FEATURE_URL_CHANGE) ? "pg" : "products.jsp"
  );

  const [_categoryId, _pgterm] = _params;

  if (_categoryId && params?.categoryId === "") {
    params.categoryId = _categoryId;
  }

  if (error && prevLocationSearch !== location.search) {
    setError(false);
    return null;
  }

  const onError = useCallback(() => {
    setError(true);
  }, [setError]);

  const nextgenCookie = useIsoCookies(["nextgenpg"], true)?.nextgenpg;

  if (error) {
    return <PageNotFoundError />;
  }

  const renderNextgenPG = nextgenCookie === "true";

  return (
    <>
      {renderNextgenPG ? (
        <NextgenProductGallery onError={onError} />
      ) : (
        <RHRProductGallery onError={onError} />
      )}
    </>
  );
};

const SwitchCG: FC<RouteComponentProps> = props => {
  const nextgenCookie = useIsoCookies(["nextgencg"], true)?.nextgencg;
  return (
    <>
      {nextgenCookie === "true" ? (
        <NextgenCollectionGallery />
      ) : (
        <CollectionGallery />
      )}
    </>
  );
};

const SwitchSearchPg = () => {
  const { params } = useParams2<{ [key: string]: string }>(
    { version: "", skipng: "" },
    { toLowerCase: true }
  );

  const nextgenCookie = useIsoCookies(["nextgenpg"], true)?.nextgenpg;

  const renderNextgenPG =
    params?.skipng === "true"
      ? false
      : params?.version === "nextgen" || nextgenCookie === "true";

  return renderNextgenPG ? (
    <NextgenProductGallery onError={() => {}} />
  ) : (
    <RHRProductGallery onError={() => {}} />
  );
};

const SwitchPDP = isCanonical => {
  const { params } = useParams2<{ [key: string]: string }>(
    { version: "", productId: "" },
    { toLowerCase: true }
  );

  return isCanonical || params?.productId ? (
    <PDPSwitcher version={params?.version} />
  ) : (
    <PageNotFoundError />
  );
};

const PageRouter = ({
  extraRoutes,
  ignoreStaticHome,
  cookies,
  brand,
  isCurrentPathAemPath,
  isEditor,
  match,
  location
}) => {
  const prefix = match.url !== "/" ? match.url : "";
  const FEATURE_URL_CHANGE = yn(env?.FEATURE_URL_CHANGE);
  const GiftRegistryPath =
    GiftRegistry ||
    /^\/(us|gb|ca|de|be|es|fr)\/(en)\/gift-registry/.test(
      `${location.pathname || ""}${location.search || ""}`
    ) ||
    /^\/(us|gb|ca|de|be|es|fr)\/(en)\/gift-registry\/index\.jsp/.test(
      `${location.pathname || ""}${location.search || ""}`
    );

  const homeRoute = window?.location?.hostname?.includes("rhguesthouse")
    ? GuestHouse
    : Home;
  ignoreStaticHome =
    ignoreStaticHome && !window?.location?.hostname?.includes("rhguesthouse");

  // TODO: Maybe redirect to an error page if the country and/or language are not supported
  return (
    <Route
      render={_ => (
        <Body>
          <Switch>
            {/* <Route component={Demo} exact path="/demo" />
              <Route component={CG} path="/demo/category" /> */}
            {FEATURE_URL_CHANGE
              ? isCurrentPathAemPath && extraRoutes
              : extraRoutes}
            <Redirect from="/index.jsp" to="/" exact />
            {
              //!ignoreStaticHome && (
              <Route path="/" component={homeRoute} exact />
              //)}
            }
            {ShoppingCartPage && (
              <Route
                exact
                path={`${prefix}/checkout/shopping_cart.jsp`}
                component={ShoppingCartPage}
              />
            )}

            {MyAccountPage && (
              <Route path={`${prefix}/my-account`} component={MyAccountPage} />
            )}

            {PrintableProductPage && (
              <Route
                path={`${prefix}/print-product.jsp`}
                component={PrintableProductPage}
              />
            )}

            <Route
              path={`${prefix}/my-account`}
              render={({ location }) => {
                const { pathname } = location;
                if (
                  OrderDetails &&
                  /^\/my-account\/order-history-detail\.jsp.+orderNo=/.test(
                    pathname ?? ""
                  )
                ) {
                  return <OrderDetails />;
                }
                if (
                  /^(\/(us|gb|ca|de|be|es|fr)\/(en|fr|de|nl|es))?\/my-account\/billing-summary\.jsp/.test(
                    pathname
                  )
                ) {
                  return <Route component={BillingSummary} />;
                }
                if (
                  MyAccountWishlist &&
                  /^\/(us|gb|ca|de|be|es|fr)\/(en)\/my-account\/wish-list\.jsp/.test(
                    pathname ?? ""
                  )
                ) {
                  return (
                    <Route
                      path={`${prefix}/my-account/wish-list.jsp`}
                      component={MyAccountWishlist}
                    />
                  );
                }

                if (
                  MyAccountPage &&
                  /^\/(us|gb|ca|de|be|es|fr)\/(en)\/my-account/.test(pathname)
                ) {
                  return (
                    <Route
                      path={`${prefix}/my-account`}
                      component={MyAccountPage}
                    />
                  );
                }

                if (
                  MyAccountAddress &&
                  /^\/(us|gb|ca|de|be|es|fr)\/(en)\/my-account\/address-book\.jsp/.test(
                    pathname ?? ""
                  )
                ) {
                  return (
                    <Route
                      path={`${prefix}/my-account/address-book.jsp`}
                      component={MyAccountAddress}
                    />
                  );
                }

                if (
                  !yn(env.FEATURE_BILLING_SUMMARY) &&
                  /^(\/(us|gb|ca|de|be|es|fr)\/(en|fr|de|nl|es))?\/my-account\/billing-summary.jsp/.test(
                    pathname
                  )
                ) {
                  return passthroughToOldExperience(cookies, location, brand);
                }

                if (
                  MyAccountPageSignIn &&
                  (/^\/my-account\/sign-out\.jsp/.test(pathname ?? "") ||
                    /^\/my-account\/sign-in\.jsp/.test(pathname ?? "") ||
                    /^\/my-account\/register\.jsp/.test(pathname ?? "") ||
                    /^\/my-account\/forgot-password\.jsp/.test(pathname ?? ""))
                ) {
                  return (
                    <Route path="/my-account" component={MyAccountPageSignIn} />
                  );
                }
              }}
            />

            {SignIn && (
              <Route exact path={`${prefix}/signin`} component={SignIn} />
            )}

            {CheckoutPageV2 &&
              (yn(env.FEATURE_REACT_CART) ||
                (/^\/checkout\/checkout-sign-in-register.jsp/i.test(
                  location.pathname
                ) &&
                  yn(env.FEATURE_REACT_MY_ACCOUNT_SIGNIN))) && (
                <Route path={`${prefix}/checkout`} component={CheckoutPageV2} />
              )}
            <Route
              path={`${prefix}/error/not-found-error.jsp`}
              component={PageNotFoundError}
              exact
            />
            <Route
              path={`${prefix}/trade-sales`}
              render={({ location }) => {
                const { pathname } = location;
                if (
                  TradeSalesPage &&
                  /^(\/(us|gb|ca|de|be|es|fr)\/(en|fr|de|nl|es))?\/trade-sales\/trade-sign-in.jsp/.test(
                    pathname
                  )
                ) {
                  return <TradeSalesPage />;
                }
              }}
            />
            <Route
              path={`${prefix}/catalog/category/collections.jsp`}
              component={SwitchCG}
              exact
            />
            <Route
              path={`${prefix}/catalog/category/collections.jsp/:categoryId`}
              component={SwitchCG}
              exact
            />
            <Route
              path={`${prefix}/catalog/category/products.jsp`}
              component={SwitchPG}
              exact
            />
            <Route
              path={`${prefix}/catalog/category/products.jsp/:categoryId/:pgterm`}
              component={SwitchPG}
              exact
            />
            <Route
              path={`${prefix}/:category/cg/:item`}
              component={SwitchCG}
              exact
            />
            <Route
              path={`${prefix}/:category/pg/:item`}
              component={SwitchPG}
              exact
            />
            <Route
              path={`${prefix}/:category/pdp/:item`}
              component={Product}
              exact
            />
            <SanitizedRoute
              path={`${prefix}/search/:searchText`}
              component={SwitchSearchPg}
              exact
            />
            {Rooms && (
              <Route
                path={`${prefix}/rooms/:roomtype`}
                render={({ location, match }) => {
                  const { didUrlChange, newUrl } = buildRoomsUrl(location);

                  if (didUrlChange) {
                    return <Redirect to={newUrl!} />;
                  } else {
                    return <Rooms match={match} />;
                  }
                }}
              />
            )}
            <Route
              path={`${prefix}/catalog/category/products.jsp/:categoryId`}
              component={SwitchPG}
              exact
            />
            <Route
              path={`${prefix}/catalog/category/sale-products.jsp`}
              component={SwitchPG}
              exact
            />
            <Route
              path={`${prefix}/outdoor/catalog/category/collections.jsp`}
              component={SwitchCG}
              exact
            />
            <Route
              path={`${prefix}/outdoor/catalog/category/collections.jsp/:categoryId`}
              component={SwitchCG}
              exact
            />
            <Route
              path={`${prefix}/outdoor/catalog/category/products.jsp`}
              component={SwitchPG}
              exact
            />
            <Route
              path={`${prefix}/outdoor/catalog/category/products.jsp/:categoryId/:pgterm`}
              component={RHRProductGallery}
              exact
            />
            <Route
              path={`${prefix}/outdoor/catalog/category/products.jsp/:categoryId`}
              component={SwitchPG}
              exact
            />
            <Route
              path={`${prefix}/outdoor/catalog/category/sale-products.jsp`}
              component={SwitchPG}
              exact
            />
            <Route
              path={`${prefix}/catalog/sale/final-sale.jsp`}
              component={SwitchPG}
              exact
            />
            {AllGalleries && (
              <Route
                path={`${prefix}/store-locations`}
                component={AllGalleries}
              />
            )}
            <Redirect
              from={`${prefix}/store-locations/index.jsp`}
              to="/store-locations/stores.jsp"
              exact
            />
            <Redirect
              from={`${prefix}/store-locations/stores`}
              to="/store-locations/stores.jsp"
              exact
            />
            <Redirect
              from={`${prefix}/store-locations/:storeId`}
              to={`${prefix}/store-locations/:storeId.jsp`}
              exact
            />

            <Route path={`/us/en/payment.jsp`} component={Payment} exact />
            <Route path={`/ca/en/payment.jsp`} component={Payment} exact />
            <Route
              path={`${prefix}/payment`}
              component={PaymentPortalRedirect}
              exact
            />
            <Route
              path={`${prefix}/payment.jsp`}
              component={PaymentPortalRedirect}
              exact
            />

            <Route
              path={`${prefix}/payment/thank-you.jsp`}
              component={PaymentThankYou}
              exact
            />

            <Route
              exact
              path={`${prefix}/payment/expired-order.jsp`}
              component={ExpiredOrder}
            />

            <Route
              path={`${prefix}/catalog/product/product.jsp`}
              // component={Product}
              component={Product} // use this when RHR PDP ready
              exact
            />
            <Route
              path={`${prefix}/catalog/product/product.jsp/:prodId`}
              component={Product}
              exact
            />
            {SwatchFulfillment && (
              <Route
                path={`${prefix}/swatch/order.jsp`}
                component={SwatchFulfillment}
                exact
              />
            )}
            <Route
              path={`${prefix}/customer-service`}
              render={({ location }) => {
                const { pathname } = location;
                if (
                  CustomerServicePage &&
                  /^(\/(us|gb|ca|de|be|es|fr)\/(en|fr|de|nl|es))?\/customer-service\/return-policy\.jsp/.test(
                    pathname ?? ""
                  )
                ) {
                  return <CustomerServicePage />;
                }
                return passthroughToOldExperience(cookies, location, brand);
              }}
            />
            {ReturnConfirmation && (
              <Route
                path={`${prefix}/return/confirmation`}
                component={ReturnConfirmation}
                exact
              />
            )}
            {ReturnReason && (
              <Route
                path={`${prefix}/return/reason`}
                component={ReturnReason}
                exact
              />
            )}
            {Return && (
              <Route path={`${prefix}/return`} component={Return} exact />
            )}
            <Route
              path={"/sitemap.jsp"}
              render={({ location }) => {
                return passthroughToOldExperience(cookies, location, brand);
              }}
            />
            {GuestUserOrderDetails && (
              <Route
                path={`${prefix}/order-details.jsp`}
                render={() => <GuestUserOrderDetails guestUser={true} />}
                exact
              />
            )}
            {Rooms && (
              <Route
                path={`${prefix}/rooms.jsp`}
                render={({ location, match }) => {
                  const { didUrlChange, newUrl } = buildRoomsUrl(location);

                  if (didUrlChange) {
                    return <Redirect to={newUrl!} />;
                  } else {
                    return <Rooms match={match} />;
                  }
                }}
              />
            )}
            {Rooms && (
              <Route
                path={`${prefix}/outdoor/rooms.jsp`}
                render={({ location, match }) => {
                  const { didUrlChange, newUrl } = buildRoomsUrl(location);

                  if (didUrlChange) {
                    return <Redirect to={newUrl!} />;
                  } else {
                    return <Rooms match={match} />;
                  }
                }}
              />
            )}
            {GiftRegistry &&
              location.search.includes("context=GiftRegistry") && (
                <Redirect to={`${prefix}/gift-registry`} />
              )}
            {GiftRegistry &&
              location.search.includes("context=GiftRegistry") &&
              /^\/(us|gb|ca|de|be|es|fr)\/(en)\/content\/category\.jsp/.test(
                `${location.pathname || ""}${location.search || ""}`
              ) && <Redirect to={`${prefix}/gift-registry`} />}

            {location.pathname === "/content/category.jsp" &&
              !location.search.includes("GiftRegistry") && (
                <Route
                  path={`${prefix}/content/category.jsp`}
                  exact
                  render={({ location }) => {
                    const { search } = location;
                    const params = new URLSearchParams(search);
                    const catalog = params.get("catalog");

                    if (catalog && catalog in brandContextMap) {
                      const redirectTo = getHostName(
                        catalog as keyof typeof SUBDOMAIN
                      );

                      return (window.location.href = redirectTo);
                    }

                    return <CategoryContextPage />;
                  }}
                />
              )}
            {location.pathname.includes("/content/category.jsp") &&
              location.pathname.includes("context=Trade") && (
                <Redirect to="/content/category.jsp?context=Trade" />
              )}
            {location.pathname.includes("/content/category.jsp") &&
              location.pathname.includes("context=Contract") && (
                <Redirect to="/content/category.jsp?context=Contract" />
              )}
            {GiftRegistry && GiftRegistryPath && (
              <Route
                path={`${prefix}/gift-registry/`}
                component={GiftRegistry}
              />
            )}

            <Route
              path={`${prefix}/content/promo.jsp`}
              component={PromoPage}
              exact
            />
            <SanitizedRoute
              path={`${prefix}/search/results.jsp`}
              component={
                // env.FEATURE_RHR && env.FEATURE_RHR_SEARCH
                SwitchSearchPg
                // : SearchResults
              }
              exact
            />
            <Route
              path={`${prefix}/catalog/search.jsp`}
              component={NextgenProductGallery}
              exact
            />
            <Route
              path={`${prefix}/outdoor/search/results.jsp`}
              component={
                // env.FEATURE_RHR && env.FEATURE_RHR_SEARCH
                SwitchSearchPg
                // : SearchResults
              }
              exact
            />
            {SelfScheduler && (
              <Route
                path={`${prefix}/self-scheduler.jsp`}
                component={SelfScheduler}
                exact
              />
            )}
            <Route
              path={`${prefix}/contract-sales`}
              render={({ location }) => {
                const { pathname } = location;
                if (
                  ContractSalesPage &&
                  /^(\/(us|gb|ca|de|be|es|fr)\/(en|fr|de|nl|es))?\/contract-sales\/contract-sign-in.jsp/.test(
                    pathname
                  )
                ) {
                  return <ContractSalesPage />;
                }
              }}
            />
            <Redirect
              from={`${prefix}/search/new-results.jsp`}
              to={{
                pathname: `${prefix}/search/results.jsp`,
                search: location.search
              }}
              exact
            />
            <Redirect
              from={`/us/en/customer-experience/email-signup`}
              to={{
                pathname: `/customer-service/email-signup.jsp`,
                search: location.search
              }}
              exact
            />
            <Redirect
              from={`/ca/en/customer-experience/email-signup`}
              to={{
                pathname: `/customer-service/email-signup.jsp`,
                search: location.search
              }}
              exact
            />
            <Route
              path={`${prefix}/error/product-not-found.jsp`}
              component={PageNotFoundError}
              exact
            />
            <Route path={`${prefix}/outdoor`} exact />

            <Route
              path={`${prefix}/error/category-not-found.jsp`}
              component={PageNotFoundError}
              exact
            />

            <Route
              path={`${prefix}/error/search-not-available.jsp`}
              component={PageNotFoundError}
              exact
            />

            <Route
              path={`${prefix}/catalog/sale/index.jsp`}
              component={SalePage}
              exact
            />
            <Route
              path={`${prefix}/interior-design`}
              render={() => {
                // TODO: https://restorationhardware.jira.com/browse/KWEB-1262

                const defaultContext = "DesignAtelierLanding";

                const contextPerBrand: { [key: string]: string } = {
                  BC: "InteriorDesignServices"
                };

                const context = contextPerBrand[brand] || defaultContext;

                return <CategoryContextPage context={context} />;
              }}
            />
            {yn(env.FEATURE_GUEST_HOUSE) && (
              <>
                <Route path={`${prefix}/guest-house`} component={GuestHouse} />
                <Route
                  path={`${prefix}/guesthouse/newyork`}
                  component={GuestHouse}
                />
              </>
            )}
            {!isEditor && (
              <Route
                render={({ location }) => {
                  if (!isCurrentPathAemPath) {
                    return passthroughToOldExperience(cookies, location, brand);
                  } else {
                    return <React.Fragment />;
                  }
                }}
              />
            )}
          </Switch>
        </Body>
      )}
    />
  );
};
export interface RouterProps {
  disableHeaderFooter?: (pathname: string, brand: Brand) => boolean;
  extraRoutes?: ReactNode[] | null;
  isEditor?: boolean;
  isLocalStorageUpdate?: boolean;
}

const Router: FC<RouterProps> = ({
  extraRoutes,
  disableHeaderFooter,
  isEditor,
  isLocalStorageUpdate
}) => {
  const location = useLocation();
  const brand = useBrand();
  const cookies = useIsoCookies([
    "JSESSIONID",
    "PF_EXP",
    "FEATURE_EU_EXPANSION_DE",
    "FEATURE_EU_EXPANSION_BE",
    "FEATURE_EU_EXPANSION_ES",
    "FEATURE_EU_EXPANSION_FR"
  ]);
  const FEATURE_EU_EXPANSION_DE =
    yn(env.FEATURE_EU_EXPANSION_DE) || yn(cookies?.FEATURE_EU_EXPANSION_DE);
  const FEATURE_EU_EXPANSION_BE =
    yn(env.FEATURE_EU_EXPANSION_BE) || yn(cookies?.FEATURE_EU_EXPANSION_BE);
  const FEATURE_EU_EXPANSION_ES =
    yn(env.FEATURE_EU_EXPANSION_ES) || yn(cookies?.FEATURE_EU_EXPANSION_ES);
  const FEATURE_EU_EXPANSION_FR =
    yn(env.FEATURE_EU_EXPANSION_FR) || yn(cookies?.FEATURE_EU_EXPANSION_FR);

  const isAemPath = useCallback(() => {
    return !(
      location.pathname.includes(".jsp") ||
      location.pathname.includes("store-locations") ||
      location.pathname.includes("my-account") ||
      location.pathname.includes("/cg") ||
      location.pathname.includes("/pg") ||
      location.pathname.includes("/pdp") ||
      location.pathname.includes("/search")
    );
  }, [location.pathname]);

  const disableHF =
    (disableHeaderFooter?.(location.pathname, brand) ?? false) ||
    isAemPath() ||
    (location.pathname.includes("/checkout") &&
      !location.pathname.includes("/shopping_cart")) ||
    (location.pathname.includes("/payment") &&
      !location.pathname.includes("my-account")) ||
    location.pathname.includes("/payment/thank-you");
  let ignoreStaticHome = isAemPath();

  const { euExpansionPaths, euExpansionFallbacks } = useMemo(() => {
    let euExpansionPaths = "";
    let euExpansionFallbacks: ReactNode[] = [];

    if (
      FEATURE_EU_EXPANSION_DE ||
      location.pathname === "/de/de" ||
      location.pathname === "/de/en" ||
      location.pathname === "/de/de/" ||
      location.pathname === "/de/en/" ||
      location.pathname.includes("sourcebook-email-signup") ||
      location.pathname.includes("privacy-policy")
    ) {
      euExpansionPaths += "|de";
    } else {
      euExpansionFallbacks.push(
        // all routes that aren't the home route, so teaser page functionality can be used
        <Route
          path="/(de)/:lang/*"
          key="/(de)/:lang/*"
          render={({ match, location }) => (
            <Redirect to={location.pathname.replace(match.url, "/us/en")} />
          )}
        />
      );
    }
    if (
      FEATURE_EU_EXPANSION_ES ||
      location.pathname === "/es/es" ||
      location.pathname === "/es/en" ||
      location.pathname === "/es/es/" ||
      location.pathname === "/es/en/" ||
      location.pathname.includes("sourcebook-email-signup") ||
      location.pathname.includes("privacy-policy")
    ) {
      euExpansionPaths += "|es";
    } else {
      euExpansionFallbacks.push(
        // all routes that aren't the home route, so teaser page functionality can be used
        <Route
          path="/(es)/:lang/*"
          key="/(es)/:lang/*"
          render={({ match, location }) => (
            <Redirect to={location.pathname.replace(match.url, "/us/en")} />
          )}
        />
      );
    }
    if (
      FEATURE_EU_EXPANSION_FR ||
      location.pathname === "/fr/fr" ||
      location.pathname === "/fr/en" ||
      location.pathname === "/fr/fr/" ||
      location.pathname === "/fr/en/" ||
      location.pathname.includes("sourcebook-email-signup") ||
      location.pathname.includes("privacy-policy")
    ) {
      euExpansionPaths += "|fr";
    } else {
      euExpansionFallbacks.push(
        // all routes that aren't the home route, so teaser page functionality can be used
        <Route
          path="/(fr)/:lang/*"
          key="/(fr)/:lang/*"
          render={({ match, location }) => (
            <Redirect to={location.pathname.replace(match.url, "/us/en")} />
          )}
        />
      );
    }

    if (
      FEATURE_EU_EXPANSION_BE ||
      location.pathname === "/be/be" ||
      location.pathname === "/be/en" ||
      location.pathname === "/be/be/" ||
      location.pathname === "/be/en/" ||
      location.pathname.includes("sourcebook-email-signup") ||
      location.pathname.includes("privacy-policy")
    ) {
      euExpansionPaths += "|be";
    } else {
      euExpansionFallbacks.push(
        <Route
          path="/(be)/:lang/*"
          key="/(be)/:lang/*"
          render={({ match, location }) => (
            <Redirect to={location.pathname.replace(match.url, "/us/en")} />
          )}
        />
      );
    }

    return { euExpansionPaths, euExpansionFallbacks };
  }, [
    FEATURE_EU_EXPANSION_BE,
    FEATURE_EU_EXPANSION_DE,
    FEATURE_EU_EXPANSION_ES,
    FEATURE_EU_EXPANSION_FR
  ]);

  const FEATURE_APP_REFRESH_POLLING = yn(env.FEATURE_APP_REFRESH_POLLING);

  const renderEmailSignupContainer = useCheckEmailPopUp()?.renderEmailSignup;

  useEffect(() => {
    document.documentElement.lang = getCountryLanguageFromURL() ?? "en-US";
  }, []);

  const [appRefreshRequired, setAppRefreshRequired] = useState(
    FEATURE_APP_REFRESH_POLLING &&
      !processEnvServer &&
      localStorage.getItem("appRefreshRequired") === "yes"
  );

  useEffect(() => {
    if (FEATURE_APP_REFRESH_POLLING && !processEnvServer) {
      setAppRefreshRequired(
        localStorage.getItem("appRefreshRequired") === "yes"
      );
    }
  }, [FEATURE_APP_REFRESH_POLLING, isLocalStorageUpdate]);

  useEffect(() => {
    if (appRefreshRequired && !processEnvServer) {
      window.location.reload();
    }
  }, [appRefreshRequired]);

  const canonical = document.querySelector(
    'link[rel="canonical"]'
  ) as HTMLLinkElement;

  useEffect(() => {
    const existingHreflangElements = document.head.querySelectorAll(
      'link[rel="alternate"][hreflang]'
    );

    if (existingHreflangElements?.length > 0) {
      return;
    }
    const addedLinkElements = [];

    const targetLocale = getLocaleFromPath(window.location.href)!;

    hreflangOptionsUnique.forEach(locale => {
      if (
        !(locale.locale.includes("de") && !FEATURE_EU_EXPANSION_DE) &&
        !(locale.locale.includes("be") && !FEATURE_EU_EXPANSION_BE) &&
        !(locale.locale.includes("es") && !FEATURE_EU_EXPANSION_ES) &&
        !(locale.locale.includes("fr") && !FEATURE_EU_EXPANSION_FR)
      ) {
        if (
          !(
            noneEnglishLocales.includes(locale.hreflang) &&
            !yn(env.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE)
          )
        ) {
          let url = window.location.href.replace(
            targetLocale?.locale,
            locale.locale
          );
          if (canonical?.href)
            url = canonical.href.replace(targetLocale?.locale, locale.locale);

          if (url.includes("/us/en")) {
            const defaultLinkElement = document.createElement("link");
            defaultLinkElement.rel = "alternate";
            defaultLinkElement.hreflang = "x-default";
            defaultLinkElement.href = url;

            document.head.appendChild(defaultLinkElement);
            addedLinkElements.push(defaultLinkElement);
          }

          url = url.replace(/\s/g, "%20");
          url = url.replace(/,/g, "");

          const linkElement = document.createElement("link");
          linkElement.rel = "alternate";
          linkElement.hreflang = locale.hreflang;
          linkElement.href = url;

          document.head.appendChild(linkElement);
          addedLinkElements.push(linkElement);
        }
      }
    });

    return () => {
      addedLinkElements.forEach(element => {
        if (element.parentNode) {
          element.parentNode.removeChild(element);
        }
      });
    };
  }, [window.location.href, canonical?.href]);

  return (
    <React.Fragment>
      {renderEmailSignupContainer && <EmailSignupContainer />}
      <Route component={disableHF ? () => null : memoize(HeaderSwitcher)} />
      <Switch>
        <Route
          path={`/(us|gb|ca${euExpansionPaths})/:lang`}
          render={({ match, location }) => {
            return (
              <PageRouter
                extraRoutes={extraRoutes}
                ignoreStaticHome={ignoreStaticHome}
                cookies={cookies}
                match={match}
                location={location}
                brand={brand}
                isCurrentPathAemPath={isAemPath()}
                isEditor={isEditor}
              />
            );
          }}
        />
        {euExpansionFallbacks}
        <Route
          render={({ match, location }) => {
            return (
              <PageRouter
                extraRoutes={extraRoutes}
                ignoreStaticHome={ignoreStaticHome}
                cookies={cookies}
                match={match}
                location={location}
                brand={brand}
                isCurrentPathAemPath={isAemPath()}
                isEditor={isEditor}
              />
            );
          }}
        />
      </Switch>
      <RHCookiePermissionBannerWrapper />
      <CountrySiteCheckDialog />
      {/* <Route component={disableHF ? () => null : GlobalPopup} /> */}
      <Route
        render={() => {
          if (!disableHF) return <RHRFooter />;
        }}
      />
    </React.Fragment>
  );
};

export default memoize(Router);

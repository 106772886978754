import { SlingElement, SlingElementProps } from "aem/ui/sling";
import React from "react";

export type DataSourceProps = Omit<SlingElementProps, "tagName">;

export type DataSourceFC = React.FC<DataSourceProps>;

export const DataSource: DataSourceFC = ({ children, ...rest }) => {
  return (
    <SlingElement {...rest} tagName={rest["tagName"]}>
      {children}
    </SlingElement>
  );
};

DataSource.defaultProps = {
  tagName: "datasource"
};

export default DataSource;

import { FormTextFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/textfield/FormTextField";
import { SlingElement, SlingElementProps } from "aem/ui/sling";
import { toString, toTagName } from "aem/ui/sling/utils";
import React from "react";

export type RichTextProps = Omit<SlingElementProps, "tagName"> &
  FormTextFieldProps;

export type RichTextFC = React.FC<RichTextProps>;

export const RichText: RichTextFC = ({ children, name, ...rest }) => {
  return (
    <SlingElement
      sling_resourceType="cq/gui/components/authoring/dialog/richtext"
      tagName={rest["tagName"] || toTagName(name)}
      useFixedInlineToolbar={toString(true)}
      {...rest}
      name={name}
    >
      {children}
    </SlingElement>
  );
};

RichText.defaultProps = {};

export default RichText;

import {
  Container,
  DropDown,
  DropDownItem,
  Items,
  TextBox
} from "aem/ui/authoring/rh";
import { asShowHideTarget } from "aem/ui/authoring/rh/behaviors/asShowHideTarget";
import { AuthoringProps } from "aem/ui/authoring/utils";
import { SlingElement } from "aem/ui/sling";
import React from "react";
import { StyleProps } from ".";

const justifyContent = [
  "",
  "center",
  "end",
  "flex-end",
  "flex-start",
  "inherit",
  "initial",
  "left",
  "normal",
  "right",
  "safe center",
  "space-around",
  "space-between",
  "space-evenly",
  "start",
  "stretch",
  "unsafe center",
  "unset"
];

const alignContent = [
  "",
  "baseline",
  "center",
  "end",
  "first baseline",
  "flex-end",
  "flex-start",
  "inherit",
  "initial",
  "last baseline",
  "normal",
  "safe center",
  "space-around",
  "space-between",
  "space-evenly",
  "start",
  "stretch",
  "unsafe center",
  "unset"
];

const alignItems = [
  "",
  "baseline",
  "center",
  "end",
  "first baseline",
  "flex-end",
  "flex-start",
  "inherit",
  "initial",
  "last baseline",
  "normal",
  "safe center",
  "self-end",
  "self-start",
  "start",
  "stretch",
  "unsafe center",
  "unset"
];

const justifyItems = [
  "",
  "auto",
  "baseline",
  "center",
  "end",
  "first baseline",
  "flex-end",
  "flex-start",
  "inherit",
  "initial",
  "last baseline",
  "left",
  "legacy center",
  "legacy left",
  "legacy right",
  "normal",
  "right",
  "safe center",
  "self-end",
  "self-start",
  "start",
  "stretch",
  "unsafe center",
  "unset"
];

export const FlexStyle = (props: AuthoringProps<StyleProps>) => (
  <>
    <Container tagName="selfFlexProps">
      <Items>
        <TextBox
          fieldDescription="In depth explanation at: https://developer.mozilla.org/en-US/docs/Web/CSS/flex-basis"
          fieldLabel="Flex Basis"
          name={props.getPath($ => ($ as any).flexBasis)}
        />
        <TextBox
          fieldDescription="In depth explanation at: https://developer.mozilla.org/en-US/docs/Web/CSS/flex-grow"
          fieldLabel="Flex Grow"
          name={props.getPath($ => ($ as any).flexGrow)}
        />
        <TextBox
          fieldDescription="In depth explanation at: https://developer.mozilla.org/en-US/docs/Web/CSS/flex-shrink"
          fieldLabel="Flex Shrink"
          name={props.getPath($ => ($ as any).flexShrink)}
        />
        <DropDown
          fieldDescription="In depth explanation at: https://developer.mozilla.org/en-US/docs/Web/CSS/justify-self"
          fieldLabel="Self Justification"
          name={props.getPath($ => ($ as any).justifySelf)}
        >
          <Items>
            {justifyItems.map(option => (
              <DropDownItem text={option} value={option} />
            ))}
          </Items>
        </DropDown>
        <DropDown
          fieldDescription="In depth explanation at: https://developer.mozilla.org/en-US/docs/Web/CSS/align-self"
          fieldLabel="Self Alignment"
          name={props.getPath($ => ($ as any).alignSelf)}
        >
          <Items>
            {alignItems.map(option => (
              <DropDownItem text={option} value={option} />
            ))}
          </Items>
        </DropDown>
      </Items>
    </Container>
  </>
);

export const FlexContainerStyle = (props: AuthoringProps<StyleProps>) => (
  <>
    {asShowHideTarget(
      <Container tagName="containerFlexProps">
        <Items>
          <DropDown
            fieldLabel="Flex Direction"
            name={props.getPath($ => ($ as any).flexDirection)}
          >
            <Items>
              {["row", "row-reverse", "column", "column-reverse"].map(
                option => (
                  <DropDownItem text={option} value={option} />
                )
              )}
            </Items>
          </DropDown>
          <DropDown
            fieldDescription="In depth explanation at: https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content"
            fieldLabel="Content Justification"
            name={props.getPath($ => ($ as any).justifyContent)}
          >
            <Items>
              {justifyContent.map(option => (
                <DropDownItem text={option} value={option} />
              ))}
            </Items>
          </DropDown>
          <DropDown
            fieldDescription="In depth explanation at: https://developer.mozilla.org/en-US/docs/Web/CSS/align-items"
            fieldLabel="Items Alignment"
            name={props.getPath($ => ($ as any).alignItems)}
          >
            <Items>
              {alignItems.map(option => (
                <DropDownItem text={option} value={option} />
              ))}
            </Items>
          </DropDown>
          <DropDown
            fieldDescription="In depth explanation at: https://developer.mozilla.org/en-US/docs/Web/CSS/align-content"
            fieldLabel="Content Alignment"
            name={props.getPath($ => ($ as any).alignContent)}
          >
            <Items>
              {alignContent.map(option => (
                <DropDownItem text={option} value={option} />
              ))}
            </Items>
          </DropDown>
          <DropDown
            fieldDescription="In depth explanation at: https://developer.mozilla.org/en-US/docs/Web/CSS/justify-items"
            fieldLabel="Items Justification"
            name={props.getPath($ => ($ as any).justifyItems)}
          >
            <Items>
              {justifyItems.map(option => (
                <DropDownItem text={option} value={option} />
              ))}
            </Items>
          </DropDown>
        </Items>
        <SlingElement tagName="granite:data" />
      </Container>,
      props.getPath($ => ($ as any).display),
      "flex"
    )}
    {FlexStyle(props)}
  </>
);

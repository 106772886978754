import React from "react";
import { DropDown, DropDownItem, Items } from ".";
import { DropDownProps } from "./DropDown";

export type FontWeightProps = DropDownProps;

export type FontWeightFC = React.FC<FontWeightProps>;

export const FontWeight: FontWeightFC = props => {
  return (
    <DropDown {...props}>
      <Items>
        {[
          "",
          "100",
          "200",
          "300",
          "400",
          "500",
          "600",
          "700",
          "800",
          "900"
        ].map(option => (
          <DropDownItem text={option} value={option} />
        ))}
      </Items>
    </DropDown>
  );
};

FontWeight.defaultProps = {
  fieldLabel: "Font Weight"
};

export default FontWeight;

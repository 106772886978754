import React from "react";
import { DropDown, DropDownItem, Items } from ".";
import { DropDownProps } from "./DropDown";

export type FontPickerProps = DropDownProps;

export type FontPickerFC = React.FC<FontPickerProps>;

export const FontPicker: FontPickerFC = props => {
  return (
    <DropDown {...props}>
      <Items>
        <DropDownItem
          text="Caslon"
          value="CaslonRHThin, Big Caslon, Palatino"
        />
        <DropDownItem text="Baron Sans" value="RHSans, Helvetica, Arial" />
        <DropDownItem
          text="Baron Sans - Ultra Hairline"
          value="RHSansUltraHairline, Helvetica, Arial"
        />
      </Items>
    </DropDown>
  );
};

FontPicker.defaultProps = {
  fieldLabel: "Font Family"
};

export default FontPicker;

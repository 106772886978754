import { SlingElement, SlingElementProps } from "aem/ui/sling";
import React from "react";

export type ColumnProps = Omit<SlingElementProps, "tagName">;

export type ColumnFC = React.FC<ColumnProps>;

export const Column: ColumnFC = ({ children, tagName }) => {
  return (
    <SlingElement
      tagName={tagName}
      sling_resourceType="granite/ui/components/foundation/container"
    >
      {children}
    </SlingElement>
  );
};

Column.defaultProps = {
  tagName: "column"
};

export default Column;

/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";

/**
 * A field that allows user to enter color.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/colorfield/index.html
 */
export type FormColorFieldProps = FormFieldProps & {
  /**
   * The name that identifies the field when submitting the form.
   */
  name?: string;
  /**
   * The value of the field.
   * This value can be set in 5 different formats (HEX, RGB, RGBA, HSB and CMYK)
   */
  value?: string;
  /**
   * A hint to the user of what can be entered in the field.
   * @attributes i18n
   */
  emptyText?: string;
  /**
   * Indicates if the field is in disabled state.
   */
  disabled?: boolean;
  /**
   * Indicates if the field is mandatory to be filled.
   */
  required?: boolean;
  /**
   * Enumeration for colorfield variant:
   * default
   * Use ColorField as a formfield
   * swatch
   * Use a simple swatch as ColorField
   */
  variant?: string;
  /**
   * Enumeration for auto generated colors state:
   * off
   * Disable auto generation
   * shades
   * Automatically generate shades (darker colors) of all colors
   * tints
   * Automatically generate tints (lighter colors) of all colors
   */
  autogenerateColors?: string;
  /**
   * Indicates if swatches view should be displayed.
   * swatches view is the right side container that allows to select color from swatches.
   */
  showSwatches?: boolean;
  /**
   * Indicates if color properties view should be displayed.
   * color properties view is the left side container having sliders, hex and RGBA inputs
   */
  showProperties?: boolean;
  /**
   * Indicates if default colors should be displayed.
   */
  showDefaultColors?: boolean;
  /**
   * The name of the validator to be applied. E.g. foundation.jcr.name.
   * See validation in Granite UI.
   * @attributes multiple
   */
  validation?: string;
  /**
   * The children.
   */
  children?: SlingElementChildren;
};

export type FormColorFieldFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormColorFieldProps
>;

export const FormColorField: FormColorFieldFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/colorfield"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormColorField.defaultProps = {
  variant: "default",
  autogenerateColors: "off"
};
